<template>
  <div>
    <b-row>
      <b-col>
        <router-link to="/mac-laptop-pc/brands">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Brands
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link to="/mac-laptop-pc/types">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Types
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link to="/mac-laptop-pc/parts">
          <b-card class="text-center cursor-pointer">
            <div>
              <h2 class="my-5 font-weight-bolder">
                Parts
              </h2>
            </div>
          </b-card>
        </router-link>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {

}
</script>
